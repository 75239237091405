import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import SliderWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/SliderWrapper"
import BlockHeader from "./BlockHeader"
import ProductVariantCard from "../molecules/ProductVariantCard"
import { useTranslation } from "react-i18next"

const ProductVariantCardsSlider = ({ uid, headlineKey, card_items }) => {
  const { t } = useTranslation()
  let showSlides = 4

  return (
    <BlockWrapper block={{}}>
      <BlockHeader className="pb-2 md:pb-8" headline={t(headlineKey)} />
      <SliderWrapper uid={uid} showSlides={showSlides} hasHeadline={true}>
        {card_items?.length > 0 &&
          card_items.map((item, index) => (
            <div className={`w-full h-full px-grid`} key={index}>
              <ProductVariantCard
                image={
                  item?.image?.filename !== "undefined"
                    ? item?.image
                    : { filename: process.env.GATSBY_IMG_PLACEHOLDER }
                }
                title={item.title}
                text={`${t("generic.products.no")} ${item.article_number}`}
                link={item.link}
              />
            </div>
          ))}
      </SliderWrapper>
    </BlockWrapper>
  )
}

export default ProductVariantCardsSlider
